<template>
  <v-navigation-drawer
    v-if="productList.length > 0"
    class="sidebar-w"
    floating
    permanent
  >
    <div class="sidebar-v-title"><h2>分類列表</h2></div>
    <v-list density="compact" nav>
      <v-list-item
        v-for="({ name, id }, index) in productList"
        :key="id"
        :class="{ active: id === publicMenuId }"
        class="sidebar-v-list-item"
      >
        <router-link
          :style="{ backgroundColor: colors[currentColor(index)] }"
          :to="`/store.linerp.co/product-lists/${providerId}/${id}`"
          class="d-flex justify-space-between link-a"
        >
          {{ name }}
          <i class="ri-arrow-right-s-fill"></i>
        </router-link>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideBarLists",
  props: {
    productList: {
      type: Array,
      default: () => [],
    },
    providerId: {
      type: String,
    },
  },
  data: () => ({
    categories: [],
    colors: [
      "rgb(185 245 255)",
      "rgb(255 240 185)",
      "rgb(226 255 185)",
      "rgb(255, 228, 225)", // 淡珊瑚色
      "rgb(255, 218, 185)", // 淡桃色
    ], // 顏色的順序在這裡定義
  }),
  created() {},
  computed: {
    publicMenuId() {
      return this.$store.getters["publicProvider/publicMenuId"];
    },
  },
  methods: {
    currentColor(index) {
      return index % this.colors.length;
    },
    async getMenuApi() {
      this.$store.dispatch("loading/active");
      try {
        const { data } = await this.$api.collection.publicApi.getProductMenu(
          this.$route.params.providerId
        );
        this.categories = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
  },
};
</script>

<style>
.sidebar-v-title h2 {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-weight: 600;
}
.sidebar-v-list-item a {
  display: block;
  color: black;
  width: 100%;
  font-size: 20px;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.sidebar-w .link-a {
  border: 1px solid white;
}
.sidebar-w .router-link-exact-active {
  border: 1px solid rgba(167, 237, 254, 0.4);
}
</style>
